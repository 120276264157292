import React, { useEffect, useState } from 'react'
import { analyticsProduct } from '@helpers/google-tag-manager'
import '../../../../assets/css/components/product/product-parts/product-variations.sass'
import classnames from 'classnames'
import { productUrl } from '@helpers/route'
import { bool, string, shape, number, func, any } from 'prop-types'
import RTGLink from '../../../shared/link'

/* eslint-disable-next-line react/prop-types */
const renderVariationBody = ({ image, variation_value }, noImage) => (
  <>
    {!noImage && image && (
      <>
        <img src={`${image}&h=36`} alt="" aria-hidden="true" role="presentation" />
        <span>{variation_value}</span>
      </>
    )}
    {noImage && <span className="text-only">{variation_value}</span>}
  </>
)

const ProductVariation = ({ variation, index, heading, productSku, handleClick = null, noImage, sliderTitle }) => {
  const [formattedAnalyticsProduct, setFormattedAnalyticsProduct] = useState(null)
  useEffect(() => {
    const setupAnalytics = () => {
      try {
        const formatted = analyticsProduct(variation, 1, index + 1)
        setFormattedAnalyticsProduct(formatted)
      } catch (error) {
        console.error('Error setting up analytics for product variation', error)
      }
    }
    setupAnalytics()
  }, [variation, index])
  const { title, sku } = variation
  const variationBody = renderVariationBody(variation, noImage)
  let route = null
  if (variation && variation.route) route = variation.route
  if (handleClick) {
    return (
      <button
        type="button"
        aria-current={productSku === variation.sku ? true : null}
        aria-describedby={`cell${productSku}`}
        className={classnames('product-variation-image', { active: productSku === sku })}
        onClick={() => handleClick(variation)}
        key={`${title}_${sku}`}
      >
        {variationBody}
      </button>
    )
  }
  return (
    <RTGLink
      noTabIndex
      className={classnames('product-variation-image', { active: productSku === variation.sku })}
      aria-selected={productSku === variation.sku ? true : null}
      key={`${variation.title}_${variation.sku}`}
      data={{
        slug: productUrl(route),
      }}
      trackingData={{
        event: 'ee_click',
        ecommerce: {
          click: {
            list: sliderTitle
              ? `${sliderTitle}`
              : `variation_${
                  heading
                    ? heading
                        .toLowerCase()
                        .split(' ')
                        .join('_')
                    : ''
                }`,
            position: index + 1,
            products: [formattedAnalyticsProduct],
          },
        },
      }}
    >
      {variationBody}
    </RTGLink>
  )
}

ProductVariation.propTypes = {
  noImage: bool,
  variation: shape({
    title: string,
    sku: string,
    image: string,
    variation_value: string,
  }),
  index: number,
  heading: string,
  productSku: string,
  handleClick: func,
  sliderTitle: any,
}

export default ProductVariation
