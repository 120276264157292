import React, { useState, useEffect } from 'react'
import { bool, string, number, object } from 'prop-types'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { productUrl } from '@helpers/route'
import Grid from '@material-ui/core/Grid'
import ChairIcon from '@mui/icons-material/Chair'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../../@rtg2022/material-ui-theme'
import RTGLink from '../../shared/link'

const useStyles = makeStyles({
  variationWrapper: {
    border: 0,
    margin: '5px 10px 10px 0',
    padding: 0,
    lineHeight: 0,
    borderRadius: '5px',
  },
  imageStyled: {
    width: '45px',
    height: '45px',
    borderRadius: '5px',
    padding: '2px',
    cursor: 'pointer',
    border: props => (props.active ? `2px solid ${theme.palette.primary.main}` : 'none'),
  },
  placeholderStyled: {
    width: '45px',
    height: '45px',
    borderRadius: '5px',
    padding: '2px',
    cursor: 'pointer',
    border: props => (props.active ? `2px solid ${theme.palette.primary.main}` : 'none'),
    display: 'grid',
    placeContent: 'center',
    overflow: 'hidden',
  },
})

const ProductVariation = ({ image, index, active = false, variation, variation_value, heading }) => {
  const [formattedAnalyticsProduct, setFormattedAnalyticsProduct] = useState(null)
  const classes = useStyles({ active })
  useEffect(() => {
    const setupAnalytics = () => {
      try {
        const formatted = analyticsProduct(variation, 1, index + 1)
        setFormattedAnalyticsProduct(formatted)
      } catch (error) {
        console.error('Error setting up analytics for product variation', error)
      }
    }
    setupAnalytics()
  }, [index, variation])
  const { sku } = variation

  let route = null
  if (variation && variation.route) route = variation.route
  return (
    <RTGLink
      noTabIndex
      aria-selected={active ? true : null}
      key={`${variation.title}_${sku}`}
      data={{
        slug: productUrl(route),
      }}
      trackingData={{
        event: 'ee_click',
        ecommerce: {
          click: {
            list: `variation_${
              heading
                ? heading
                    .toLowerCase()
                    .split(' ')
                    .join('_')
                : ''
            }`,
            position: index + 1,
            products: [formattedAnalyticsProduct],
          },
        },
      }}
    >
      <Grid container alignItems="center" direction="column" justifyContent="center" wrap="wrap" item key={index}>
        <div className={classes.variationWrapper}>
          {image ? (
            <img className={classes.imageStyled} src={`${image}&h=36`} alt={variation_value} />
          ) : (
            <div className={classes.placeholderStyled}>
              <ChairIcon sx={{ color: '#333' }} />
            </div>
          )}
        </div>
      </Grid>
    </RTGLink>
  )
}

ProductVariation.propTypes = {
  variation: object,
  active: bool,
  route: string,
  image: string,
  index: number,
  sku: string,
  heading: string,
  variation_value: string,
}

export default ProductVariation
